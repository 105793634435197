import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactUsPage = () => (
  <Layout>
    <SEO title="Contact Us "
      description="Contact Us"
      keywords="Contact Bubble Insurance"/>

    <contact-us/>
  </Layout>
);

export default ContactUsPage;
